export const liveCountTableConf = [
	{
		label: '直播日期',
		prop: 'zbtime',
	},
	{
		label: '截至时间',
		prop: 'zbtime'
	},
	{
		label: '直播主题',
		prop: 'zb_name',
	},
	{
		label: '直播名称',
		prop: 'zb_name'
	},
	{
		label: '负责人',
		prop: 'username',
	},
	{
		label: '操作',
		prop: 'operation',
	}
]

export const liveDetailTableConf = [
	{
		label: '序号',
		prop: 'account1',
	},
	{
		label: '商品信息',
		prop: 'account2'
	},
	{
		label: '商品标题',
		prop: 'title',
	},
	{
		label: '店铺名称',
		prop: 'nick',
	},
	{
		label: '商品类目',
		prop: 'name'
	},
	{
		label: '商品链接',
		prop: 'item_url',
	},
	{
		label: '市场价',
		prop: 's_price',
	},
	{
		label: '到手价',
		prop: 'zb_price',
	},
	{
		label: '商品优势介绍',
		prop: 'content',
	},
	{
		label: '佣金',
		prop: 'yx_rate',
	},
	{
		label: '佣金链接',
		prop: 'rate_url',
	},
	{
		label: '优惠券链接',
		prop: 'rate_url',
	},
	{
		label: '操作',
		prop: 'operation',
	}
]

export const formConfList = {
	1: [
		{
			label: '合同模板',
			prop: '1',
			type: 'select',
			options: []
		},
		{
			label: '保证金',
			prop: '2',
			type: 'input'
		},
		{
			label: '服务费',
			prop: '3',
			type: 'input'
		},
		{
			label: '收款信息',
			prop: '4',
			type: 'select',
			options: []
		}
	],
	2: [
		{
			label: '保证金金额',
			prop: '2',
			type: 'input'
		},
		{
			label: '收款信息',
			prop: '4',
			type: 'select',
			options: []
		}
	],
	3: [
		{
			label: '服务费金额',
			prop: '2',
			type: 'input'
		},
		{
			label: '收款信息',
			prop: '4',
			type: 'select',
			options: []
		}
	],
	4: [
		{
			label: '佣金链接',
			prop: '2',
			type: 'input'
		},
	]
}