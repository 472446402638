<template>
	<div class="live_detail">
		<div class="top">
			<img height="90" width="100%" src="https://kunheng-web-static.oss-cn-hangzhou.aliyuncs.com/anchor/paiqi_head.png" alt="" srcset="">
			<div class="content flex_c f_jc_c">
				<el-input style="width: 220px;margin: 0 auto;text-align: center" class="fs20 fw_b tc" @blur="zbname(live)" v-model="live.zb_name"></el-input>
				<ul class="flex_r f_jc_c pt10 pb10">
					<li>直播时间：{{live.zbtime}}</li>
					<li>主播：{{live.username}}</li>
					<li>负责人：{{live.username}}</li>
					<li class="tc">备注：</li>
					<el-button class="sub" style="margin-right: 180px" @click="all">显示全部</el-button>
					<el-button class="sub" style="margin-right: 100px" @click="print">打印</el-button>
					<el-popover
							placement="bottom"
							title=""
							width="400"
							trigger="click">
						<div class="tc">
							<el-input disabled v-model="copyUrl"></el-input>
							<el-button class="mt20" @click="copyActive">点击复制</el-button>
						</div>
						<el-button class="sub" slot="reference" @click="fz(live.id,$event)">立即邀请</el-button>
					</el-popover>
				</ul>
			</div>
		</div>
		<e-table @query-Table-data="handleCurrentChange" :showPagination="showPagination" ref="tables" :tableCols="liveDetailTableConf" :dataOrigin="dataOrigin" id="print">
			<template #account1="scope">
				<el-input style="width: 60px;" @blur="px(scope.rowData)" v-model="scope.rowData.weigh"></el-input>
			</template>
			<template #account2="scope">
				<el-popover
					trigger="hover">
					<img height="300" width="300" :src="scope.rowData.pict_url" alt="">
					<img slot="reference" height="80" width="80" :src="scope.rowData.pict_url" alt="">
				</el-popover>
			</template>
			<template #title="scope">
				<el-link class="lh_12" type="primary" :href="scope.rowData.item_url" target="_blank">{{ scope.rowData.title }}</el-link>
			</template>
			<template #item_url="scope">
				<el-link class="lh_12" type="primary" :href="scope.rowData.item_url" target="_blank">{{ scope.rowData.item_url }}</el-link>
			</template>
			<template #zb_price="scope">
				<el-input class="lh_12" @blur="zbprice(scope.rowData)" v-model="scope.rowData.zb_price" :value="scope.rowData.zb_price" />
			</template>
			<template #content="scope">
				<div class="html-content" v-html="scope.rowData.content"></div>
			</template>
			<template #account5>
				<el-button @click="showDialog(1)">发起合同</el-button>
			</template>
			<template #account6>
				<el-button @click="showDialog(2)">发起保证金</el-button>
			</template>
			<template #account7>
				<el-button @click="showDialog(3)">发起服务放</el-button>
			</template>
			<template #account9>
				<el-button @click="showDialog(4)">待提交</el-button>
			</template>
			<template #operation="scope">
				<div></div>
				<router-link tag="a" target="_blank" :to="{path:'/handCardDetail',query: {id:scope.rowData.id}}">查看手卡</router-link>
				<div class="cur_p">
					<div @click="sj(scope.rowData)">上架</div>
				</div>
				<div class="cur_p">
					<div @click="xj(scope.rowData)">下架</div>
				</div>
				<div class="cur_p">
					<div @click="gj(scope.rowData.id)">修改价格</div>
				</div>
				<div class="cur_p">
					<div @click="gs(scope.rowData.id)">修改库存</div>
				</div>
				<div class="cur_p">
					<div @click="bh(scope.rowData.id)">一键驳回</div>
				</div>
			</template>
		</e-table>
		<e-dialog :title="dialogTitle" ref="dialogRef">
			<e-form :formConfList="formConfListItem" />
		</e-dialog>
		<e-dialog width="846px" title="修改sku信息" ref="dialogConfRef" v-on:confirm="skuConfirm">
			<el-form label-width="auto" inline>
				<el-form-item>
					<el-form-item class=" mt10"  label="批量设置">
						<el-input disabled class="w_125"></el-input>
					</el-form-item>
					<el-form-item class=" mt10"  label="价格">
						<el-input class="w_125" @blur="plPrice()" v-model="pl.price"></el-input>
					</el-form-item>
					<el-form-item class=" mt10"  label="库存">
						<el-input class="w_125" @blur="plSku()" v-model="pl.sku"></el-input>
					</el-form-item>
				</el-form-item>
				<el-form-item  v-for="sku in skuItem" :key="sku.index">
					<el-form-item class=" mt10"  label="规格">
						<el-input disabled class="w_125" v-model="sku.attr_name"></el-input>
					</el-form-item>
					<el-form-item class=" mt10"  label="价格">
						<el-input class="w_125" v-model="sku.price"></el-input>
					</el-form-item>
					<el-form-item class=" mt10"  label="库存">
						<el-input class="w_125" v-model="sku.sku"></el-input>
					</el-form-item>
				</el-form-item>
			</el-form>
		</e-dialog>
		<e-dialog title="修改sku信息" ref="diapriceRef" v-on:confirm="priceConfirm">
			<el-form label-width="auto" >
				<el-form-item label="价格">
					<el-input v-model="price"></el-input>
				</el-form-item>
			</el-form>
		</e-dialog>
		<e-dialog title="修改sku信息" ref="diaskuRef" v-on:confirm="skuSingleConfirm">
			<el-form label-width="auto" >
				<el-form-item label="库存">
					<el-input v-model="sku"></el-input>
				</el-form-item>
			</el-form>
		</e-dialog>
		<e-dialog title="审核驳回" ref="dialogSponsorRef" v-on:confirm="confirm">
			<el-form label-width="auto" >
				<el-form-item label="驳回备注">
					<el-input :rows="6" type="textarea" v-model="formData.memo" placeholder=""></el-input>
				</el-form-item>
			</el-form>
		</e-dialog>
	</div>
</template>

<script>
import {liveDetailTableConf, formConfList} from '@data/liveCount'
import copy from "../../common/copy";
import printJS from "print-js"
export default {
	name: 'LiveDetail',
	data() {
		return {
			liveDetailTableConf,
			formConfList,
			dataOrigin: {
				data: []
			},
			formConfListItem: [],
			dialogTitle: '',
			id: '',
			detail_id: '',
			live: {},
			copyUrl: '',
			showPagination: true,
			skuItem: [],
			price: '',
			sku: '',
			pl: {},
			total: 1,
			currentPage: 1,
			formData: {},
			sid: ''
		}
	},
	methods: {
		bh(id){
			this.sid = id
			this.$refs.dialogSponsorRef.handleOpen()
		},
		confirm(){
			let that = this
			if(this.formData.memo == '' || this.formData.memo == undefined){
				this.$message.error("请填写驳回备注");
				return
			}
			this.post('?server=live.bh.push.handle.post',{goods_id:this.sid,memo:this.formData.memo}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				that.$message.success(response.data.msg);
				console.log(response)
				that.$refs.dialogSponsorRef.handleClose()
				that.GetDetail()
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
		plPrice(){
			if(this.pl.price){
				let len = this.skuItem.length
				// console.log(len)
				for(let i=0;i<len;i++){
					// console.log(response.data.data[i])
					this.skuItem[i].price = this.pl.price
				}
			}
		},
		plSku(){
			if(this.pl.sku){
				let len = this.skuItem.length
				// console.log(len)
				for(let i=0;i<len;i++){
					// console.log(response.data.data[i])
					this.skuItem[i].sku = this.pl.sku
				}
			}
		},
		sj(data){
			let that = this

			this.$confirm('是否在淘宝上架该商品?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				that.post('?server=live.seller.shop.goods.on.sale.in.tb.post',{
					id:data.id}).
				then(function (response) {
					//这里只会在接口是成功状态返回
					//联网成功的回调
					that.$message({
						type: 'success',
						message: response.data.msg
					});
					that.GetActiveLists()
				}).catch(function (error) {
					//这里只会在接口是失败状态返回，不需要去处理错误提示
					console.log(error);
				});
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消'
				});
			});
		},
		xj(data){
			let that = this

			this.$confirm('是否在淘宝下架该商品?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				that.post('?server=live.seller.shop.goods.down.sale.in.tb.post',{
					id:data.id}).
				then(function (response) {
					//这里只会在接口是成功状态返回
					//联网成功的回调
					that.$message({
						type: 'success',
						message: response.data.msg
					});
					that.GetActiveLists()
				}).catch(function (error) {
					//这里只会在接口是失败状态返回，不需要去处理错误提示
					console.log(error);
				});
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消'
				});
			});
		},
		gj(id){
			let that = this
			this.id = id
			this.pl = []
			this.get('?server=live.seller.shop.goods.sku.price.in.tb.get',{id: id}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调

				if(response.data.skuid == ''){
					that.price = response.data.price
					that.$refs.diapriceRef.handleOpen()
				} else {
					that.skuItem = [];
					let len = response.data.skuid.length
					// console.log(len)
					for(let i=0;i<len;i++){
						// console.log(response.data.data[i])
						that.skuItem.push(response.data.skuid[i])
					}
					that.$refs.dialogConfRef.handleOpen()
					console.log(that.skuItem)
				}

				// loading.close()
				// console.log(that.form)
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				// loading.close()
				that.$message.error('请求超时');
				console.log(error);
			});
			// let loading = this.$loadings.service({
			// 	'text': "请求中",
			// 	background: 'rgba(28,28,28,0.7)',
			// })
		},
		gs(id){
			let that = this
			this.id = id
			this.pl = []
			this.get('?server=live.seller.shop.goods.tb.sku.get',{id: id}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调

				if(response.data.skuid == ''){
					that.sku = response.data.sku
					that.$refs.diaskuRef.handleOpen()
				} else {
					that.skuItem = [];
					let len = response.data.skuid.length
					// console.log(len)
					for(let i=0;i<len;i++){
						// console.log(response.data.data[i])
						that.skuItem.push(response.data.skuid[i])
					}
					that.$refs.dialogConfRef.handleOpen()
					console.log(that.skuItem)
				}

				// loading.close()
				// console.log(that.form)
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				// loading.close()
				that.$message.error('请求超时');
				console.log(error);
			});
		},
		skuSingleConfirm(){
			let that = this
			this.get('?server=live.seller.shop.goods.tb.sku.update',{id: this.id,sku:this.sku}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				that.$message.success(response.data.msg);
				that.$refs.diaskuRef.handleClose()
				// loading.close()
				// console.log(that.form)
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				// loading.close()
				that.$message.error('请求超时');
				console.log(error);
			});
		},
		priceConfirm(){
			let that = this
			this.get('?server=live.seller.shop.goods.sku.price.update',{id: this.id,price:this.price}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				that.$message.success(response.data.msg);
				that.$refs.diapriceRef.handleClose()
				// loading.close()
				// console.log(that.form)
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				// loading.close()
				that.$message.error('请求超时');
				console.log(error);
			});
		},
		skuConfirm(){
			let that = this
			this.get('?server=live.seller.shop.goods.tb.sku.all.update',{id: this.id,sku:this.skuItem}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				console.log(response)
				that.$message.success(response.data.msg);
				that.$refs.dialogConfRef.handleClose()
				// loading.close()
				// console.log(that.form)
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				// loading.close()
				that.$message.error('请求超时');
				console.log(error);
			});
		},
		print(){
			const style = '@page { margin: 0 } @media print { .noprint{display: none;} }#printCons {width: 600px;} table tr td,th { border-collapse: collapse;padding: 15px;} input {width: 60px;} td {width: 220px;border:1px #eee solid;}' +
					'.el-table thead {color: #909399;font-weight: 500;}'
			printJS({
				printable: 'print',
				type: 'html',
				style: style,
				scanStyles: false,
				//properties: [
				//    { field: 'name', displayName: '姓名', columnSize:`50%`},
				//    { field: 'sex', displayName: '性别',columnSize:`50%`},
				//],
				header: '<p class="custom-p" style="text-align:center;font-size: 16px;"> '+this.live.zb_name+' </p>',
				// style: '#printCons {width: 600px;} .no-print{width: 0px} .itemText1 { width: 200px } .itemText2 { width: 200px } .itemText3 { width: 200px } .itemText4 { width: 200px }',
				// gridHeaderStyle:'font-size:12px; padding:3px; border:1px solid; font-weight: 100; text-align:left;',
				// gridStyle:'font-size:12px; padding:3px; border:1px solid; text-align:left;',
				// repeatTableHeader: true,
				// scanStyles:true,
				// targetStyles: ['*'],
				// ignoreElements:['no-print','bc','gb']
			})
		},
		all(){
			let that = this
			that.dataOrigin.data = []
			this.showPagination = false
			this.$refs.tables.loading = true
			that.$refs.tables && that.$refs.tables.initData()
			this.get('?server=Live.zb.time.Detail.lists.Get',{id:this.detail_id,type:'all'}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				let len = response.data.lists.length
				// console.log(len)
				for(let i=0;i<len;i++){
					// console.log(response.data.data[i])
					that.dataOrigin.data.push(response.data.lists[i])
				}
				that.live = response.data.live
				that.$refs.tables.loading = false
				that.$refs.tables.initData()
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
		zbname(e){
			let that = this
			that.post('?server=live.zbname.push.handle.post',{
				zbid:e.id,zbname:e.zb_name}).
			then(function (res) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				that.$message.success(res.data.msg)
				// copy(res.data.url, e)
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
		zbprice(e){
			let that = this
			that.post('?server=live.zbprice.push.handle.post',{
				id:e.id,zbprice:e.zb_price}).
			then(function (res) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				that.$message.success(res.data.msg)
				// copy(res.data.url, e)
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
		px(e){
			let that = this
			that.post('?server=live.yd.push.handle.post&method=px',{
				goods_id:e.id,px:e.weigh,method:"px"}).
			then(function (res) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				that.$message.success(res.data.msg)
				that.GetDetail()
				// copy(res.data.url, e)
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
		copyActive(e){
			copy(this.copyUrl, e)
		},
		fz(id,e){
			console.log(e)
			let that = this
			that.copyUrl = ''
			let loading = this.$loadings.service({
				'text': "请求中",
				background: 'rgba(28,28,28,0.7)',
			})
			that.get('?server=live.zb.invite.url.handle',{
				id:id}).
			then(function (res) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				that.copyUrl = res.data.origin
				// copy(res.data.url, e)
				loading.close()
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
				loading.close()
			});
		},
		handleCurrentChange(data){
			this.$refs.tables.loading = true
			this.currentPage = data.currentPage
			this.GetDetail()
		},
		GetDetail(){
			let that = this
			that.dataOrigin.data = []
			that.$refs.tables && that.$refs.tables.initData()
			this.currentPage = that.$refs.tables ? that.$refs.tables.currentPage : 1
			this.get('?server=Live.zb.time.Detail.lists.Get',{id:this.detail_id,page:this.currentPage}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				let len = response.data.lists.data.length
				// console.log(len)
				for(let i=0;i<len;i++){
					// console.log(response.data.data[i])
					that.dataOrigin.data.push(response.data.lists.data[i])
				}
				that.total = response.data.lists.total
				that.$refs.tables.total = response.data.lists.total
				that.live = response.data.live
				that.$refs.tables.loading = false
				that.$refs.tables.initData()
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
		showDialog(val) {
			switch(val) {
				case 1:
					this.dialogTitle = '新增样品';
					break
				case 2:
					this.dialogTitle = '新增保证金';
					break
				case 3:
					this.dialogTitle = '新增服务费';
					break
				case 4:
					this.dialogTitle = '佣金链接';
					break
			}
			this.formConfListItem = this.formConfList[val]
			this.$refs.dialogRef.handleOpen()
		},
	},
	created() {
		this.$nextTick(() => {
			this.$refs.tables.loading = true
		});
		this.detail_id = this.$route.query.id

		if(!this.detail_id){
			this.$message.error('请求非法')
		} else {
			this.GetDetail()
		}
	}
}
</script>

<style lang="scss" scoped>
.top {
	position: relative;
	.content {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		ul {
			position: relative;
			li {
				padding: 0 10px;
			}
			.sub {
				position: absolute;
				right: 100px;
			}
		}
	}
}
</style>